import throttle from 'lodash/throttle'
import find from 'lodash/find'

document.addEventListener('DOMContentLoaded', () => {

  const navbar = document.querySelector('nav.navbar')
  const navbarHeight = 75 // Navbar height when shrink
  const navbarCollapse = navbar.querySelector('#navbar-collapse')
  const navLinks = navbar.querySelectorAll('.nav-link.page-scroll')
  const scrollLinks = document.querySelectorAll('a.page-scroll:not(.nav-link)')
  const sections = Array.from(document.querySelectorAll('header[id], section[id]')).reverse()

  function resetNavLinks() {
    navLinks.forEach(l => {
      l.parentElement.classList.remove('active')
      l.classList.remove('active')
    })
  }

  function shrinkNavbar() {
    if (window.pageYOffset >= 50) {
      navbar.classList.add('navbar-shrink')
    } else {
      navbar.classList.remove('navbar-shrink')
    }
  }

  function highlightMenuEntry() {
    sections.forEach(s => {
      const navbarHeight = document.querySelector('.navbar').clientHeight
      const rect = s.getBoundingClientRect()
      const isVisible = rect.top < window.innerHeight && rect.bottom - navbarHeight >= 0;
      if (isVisible) {
        resetNavLinks()
        const link = find(navLinks, l => l.hash === `#${s.id}`)
        link.parentElement.classList.add('active')
        link.classList.add('active')
      }
    })
  }

  window.addEventListener('scroll', throttle(() => {
    shrinkNavbar()
    highlightMenuEntry()
  }, 300, {leading: true, trailing: true, maxWait: 300}))

  function scrollToHandler(e, element) {
    if (element.href.indexOf(window.location.pathname) !== -1) {
      e.preventDefault()
      const section = document.getElementById(element.href.split('#')[1])
      const bodyRect = document.body.getBoundingClientRect().top;
      const position = section.getBoundingClientRect().top
      window.scrollTo({top: position - bodyRect - navbarHeight + 1, behavior: 'smooth'})
      $(navbarCollapse).collapse && $(navbarCollapse).collapse('hide')
    }
  }

  function navLinkOnClick(e) {
    e.preventDefault()
    resetNavLinks()
    e.target.parentElement.classList.add('active')
    e.target.classList.add('active')
    scrollToHandler(e, e.target)
  }

  navLinks.forEach(l => l.addEventListener('click', navLinkOnClick))
  scrollLinks.forEach(l => l.addEventListener('click', e =>
      scrollToHandler(e, e.target).then(_ => resetNavLinks())
  ))
})